import { useEffect } from 'react';
import Head from 'next/head';
import 'assets/scss/main.scss';
import KontentSmartLink from '@kontent-ai/smart-link';
import { SessionProvider } from 'next-auth/react';
import { merriweather_sans, noto_sans_jp, noto_sans_kr } from 'lib/utils/fonts';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
import store from 'lib/store';

function App({ Component, pageProps: { session, ...pageProps } }) {
    useEffect(() => {
        const kontentSmartLink = KontentSmartLink.initialize({
            defaultDataAttributes: {
                projectId: process.env.KONTENT_PROJECT_ID,
                languageCodename: pageProps?.data?.head?.language,
            },
            queryParam: 'preview-mode',
        });
        return () => {
            kontentSmartLink.destroy();
        };
    });
    useEffect(() => {
        if (!pageProps?.data?.head?.gtm) return;
        TagManager.initialize({ gtmId: `GTM-${pageProps?.data?.head?.gtm}` });
    }, []);

    return (
        <Provider store={store}>
            <SessionProvider session={session}>
                <Head>
                    <link
                        rel="shortcut icon"
                        href={`/${process.env.KONTENT_COLLECTION}.ico`}
                    />
                </Head>
                <div
                    className={`${merriweather_sans.variable} ${noto_sans_jp.variable} ${noto_sans_kr.variable} ${pageProps?.data?.head?.font}`}
                    style={{ ...pageProps?.data?.theme }}
                >
                    <div id="portal"></div>
                    <Component {...pageProps} />
                </div>
            </SessionProvider>
        </Provider>
    );
}

export default App;
